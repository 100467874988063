import React, { useState, useEffect } from "react";
import { Divider, Card, Grid, Button } from "semantic-ui-react";
import _ from "lodash";
import Pie from "../../components/graphs/pie";

const PieCharts = ({ submittedList, questions }) => {
  const [loading, updateLoading] = useState(true);
  const [total_entries, update_total_entries] = useState(0);
  const [new_questions_data, update_new_questions_data] = useState([]);

  useEffect(() => {
    update_total_entries(submittedList.length);
  }, [submittedList]);

  useEffect(() => {
    processPieData();
  }, []);

  async function processPieData() {
    //We take the original form as add data to it

    var arr = [...questions];
    if (arr.length === 0) {
      return;
    }
    arr.map((section) => {
      section.pie_data = [];
      section.other_responses = [];
    });
    //Map though the submitted data
    submittedList.map((entry) => {
      const form = JSON.parse(entry.submissions_array);
      form.map((form_section, index) => {
        if (arr[index].type === "3" || arr[index].type === "4") {
          arr[index].other_responses.push(form[index]);
        }
        if (form_section === null) {
          return;
        }
        arr[index].pie_data.push(form_section.name);
      });
    });
    arr.map((section, index) => {
      var total_count = 0;
      const unique_array = _.uniq(section.pie_data);
      var result = _.values(_.groupBy(section.pie_data)).map((d) => ({
        name: d[0],
        count: d.length,
      }));
      var new_pie_array = [];
      result.map((d) => {
        new_pie_array.push({
          id: d.name,
          label: d.name,
          value: d.count,
        });
        total_count = total_count + d.count;
      });
      arr[index].pie_data = new_pie_array;
      arr[index].total_count = total_count;
    });
    update_new_questions_data(arr);
    updateLoading(false);
  }

  function renderSections() {
    const sections = new_questions_data.map((section, index) => {
      if (section.type === "3" || section.type === "4") {
        return (
          <div>
            <h2>{section.name}</h2>
            {section.other_responses.map((response) => {
              return <div>{response}</div>;
            })}
            {/* <Button onClick={() => console.log(section.other_responses)}>
              Test
            </Button> */}
          </div>
        );
      }
      return (
        <div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <h2>{section.name}</h2>
                <h2>{section.type}</h2>
                <Divider />
                {renderStatsList(section)}
              </Grid.Column>
              <Grid.Column width={10}>
                <Pie data={section.pie_data} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
        </div>
      );
    });
    return <div>{sections}</div>;
  }
  function renderStatsList(section) {
    const parsed_data = JSON.parse(section.options_array);
    const mapIt = parsed_data.map((btn) => {
      var result = _.find(section.pie_data, ["id", btn.name]);
      if (result !== undefined) {
        const percentage = Math.floor(
          (result.value / section.total_count) * 100
        );
        return (
          <div className="list_item">
            <div className="list_item_percentage">{percentage}%</div> responded{" "}
            <div className="list_item_label">{btn.name}</div>{" "}
          </div>
        );
      }
    });
    return <div>{mapIt}</div>;
  }

  return (
    <div>
      <Card>
        <div className="task_box">{total_entries}</div>
        <div className="task_box_text">Total Responses</div>
      </Card>
      <Divider />

      {loading ? <></> : <div>{renderSections()}</div>}
    </div>
  );
};

export default PieCharts;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";

import {
  fetchAudits,
  fetchSubmissions,
  removeSubmission,
  fetchFlows,
  fetchQuestions,
} from "../../../actions";
import { TableSubmissions } from "../../../components";

import { Menu, Divider, Button, Icon } from "semantic-ui-react";

import PieCharts from "../../../collections/analytics/pieCharts";
import { getImageData } from "gatsby-plugin-image";

const SubmissionsList = ({ search }) => {
  const dispatch = useDispatch();
  const submissions = useSelector((state) => state.submissions);
  const audits = useSelector((state) => state.audits);
  const flows = useSelector((state) => state.flows);
  const questions = useSelector((state) => state.questions);
  const practice = useSelector((state) => state.auth.practice_id);

  const [loading, update_loading] = useState(true);
  const [audit_id, update_audit_id] = useState(null);

  const [activeItem, updateActiveItem] = useState("Charts");

  useEffect(async () => {
    async function getData() {
      //const practice_id = "816f3e5d-071f-4704-bd9d-34768f52845f";
      await dispatch(fetchFlows());
      await dispatch(fetchAudits(practice));
      await dispatch(fetchSubmissions(search.id));
    }

    await getData();
    update_audit_id(search.id);
    //update_loading(false);
  }, []);

  useEffect(() => {
    console.log("audits");
    console.log(audits);
    const audit = _.find(audits, { id: search.id });

    if (audit !== undefined) {
      dispatch(fetchQuestions(audit.flow_id));
      update_loading(false);
    }

    //update_loading(false);
  }, []);

  function handleRemoveEntry(entry) {
    console.log(entry);
    dispatch(removeSubmission(entry));
  }

  function renderOptions() {
    switch (activeItem) {
      case "Charts":
        return (
          <PieCharts
            submittedList={submissions}
            questions={_.orderBy(questions, "createdAt")}
          />
        );
        break;
      case "All Data":
        return (
          <TableSubmissions
            content={submissions}
            handleRemoveEntry={handleRemoveEntry}
            header_array={["id", "Created At", "Answers", "Remove"]}
          />
        );
        break;

      default:
        break;
    }
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  if (loading === false) {
    return (
      <div>
        <div>
          <div>{JSON.stringify(practice)}</div>
          <Menu pointing secondary>
            <Menu.Item
              name="Charts"
              active={activeItem === "Charts"}
              onClick={() => updateActiveItem("Charts")}
            />
            <Menu.Item
              name="Patient Flow"
              active={activeItem === "Flow"}
              onClick={() => updateActiveItem("Flow")}
            />
            <Menu.Item
              name="All Data"
              active={activeItem === "All Data"}
              onClick={() => updateActiveItem("All Data")}
            />
          </Menu>
        </div>

        {renderOptions()}
      </div>
    );
  }
};

export default SubmissionsList;
